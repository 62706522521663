import { Box, Button, Typography } from '@mui/material'
import React, { ChangeEvent } from 'react'
import UploadFileIcon from '@mui/icons-material/UploadFile';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ClearIcon from '@mui/icons-material/Clear';
interface Props {
    FileSelector: (file: File | undefined) => void
    file: File | undefined
    FileClear: () => void
    label: string
}
export const UploadFileButton = ({ FileSelector, file, FileClear, label }: Props) => {
    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        // if (file) {
        //     const allowedFileTypes = [
        //         "image"
        //     ];
        //     if (allowedFileTypes.includes(file.type)) {
        //         console.log(file);
        //         FileSelector(file);
        //     } else {
        //         alert("El tipo de archivo seleccionado no es válido. Por favor seleccione un archivo PDF, DOC, DOCX, TXT o RTF.");
        //     }
        // }
        FileSelector(file);

    }
    return (
        <Box>
            <Typography variant='body1'>
                {label}
            </Typography>
            <>
                <input
                    type="file"
                    id="file-upload"
                    style={{ display: "none" }}
                    // className={classes.input}
                    onChange={handleFileChange}
                    accept="image/*"
                />
                <label htmlFor="file-upload">
                    <Button component="span" variant="contained" color="primary">
                        Subir archivo <UploadFileIcon></UploadFileIcon>
                    </Button>
                </label>
                {
                    file !== undefined &&
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 1,
                            alignItems: 'center',
                            marginTop: 2
                        }}
                    >
                        <InsertDriveFileIcon />
                        <Typography>
                            {file.name}
                        </Typography>
                        <ClearIcon
                            onClick={FileClear}
                            sx={{
                                cursor: 'pointer',
                                transition: "all 0.1s ease-in",
                                fontSize: '1rem',
                                ":hover": {
                                    color: '#ffa400',
                                    fontSize: '1.5rem',
                                }
                            }}
                        />
                    </Box>

                }

            </>
        </Box>
    )
}

import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { UserAuth, loading, ggDataSlice, getTransactions } from "./slice";
import GGAgent from "./agent";

export const getUserGG = (id: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
        GGAgent.getByID(id)
            .then((result) => {
                console.log(result);

            }).catch((err) => {
                console.log(err);

            });
    }
}

import React from 'react'
import { Transactions } from '../../redux/transactions/slice'
import { Box, Divider, Grid, List, ListItem, Paper, Typography } from '@mui/material'
import moment from 'moment'
interface Props {
    transactions: Transactions[]
}
export const TransactionListMobil = ({ transactions }: Props) => {
    return (
        <Box
            display={{ xs: "block", md: "none" }}
        >{
                transactions.map((transaction, zIndex) =>
                    <Box
                    >
                        <Box
                            p={2}
                            sx={{
                                // width: "100%",
                                backgroundColor: "#ebebeb",
                                minHeight: 100,
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <Typography fontWeight={500} fontSize={16}
                                    sx={{
                                        "span": {
                                            fontWeight: 400
                                        }
                                    }}
                                >
                                    Fecha de compra: <span>{moment(transaction.createdAt).format("DD MMMM YYYY")} </span>

                                </Typography>

                                <Typography fontWeight={500} fontSize={16}
                                    sx={{
                                        "span": {
                                            fontWeight: 400
                                        }
                                    }}
                                >
                                    Numero de transacción: <span>{transaction.paymentId} </span>
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <Typography fontWeight={500} fontSize={16}
                                    sx={{
                                        "span": {
                                            fontWeight: 400
                                        }
                                    }}
                                >
                                    Estado: <span>{transaction.status.toUpperCase()} </span>

                                </Typography>

                                <Typography fontWeight={500} fontSize={16}
                                    sx={{
                                        "span": {
                                            fontWeight: 400
                                        }
                                    }}
                                >
                                    Metodo de pago: <span>{transaction.paymentMethod.toUpperCase()} </span>
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <Typography fontWeight={500} fontSize={16}
                                    sx={{
                                        "span": {
                                            fontWeight: 400
                                        }
                                    }}
                                >
                                    Total: <span>${transaction.price} </span>

                                </Typography>
                            </Box>

                        </Box>
                        <Box>
                            {
                                transaction.Products.map((product, zIndex) =>
                                    <Box key={zIndex} p={2}>
                                        <Typography fontWeight={500} fontSize={16}>
                                            ID: {product.itemId}
                                        </Typography>
                                        <Typography fontWeight={500} fontSize={16}>
                                            Cantidad: {product.quantity}
                                        </Typography>
                                        <Typography fontWeight={500} fontSize={16}>
                                            Precio: {product.price}
                                        </Typography>
                                        <Divider />
                                    </Box>
                                )
                            }
                        </Box>
                    </Box>

                )
            }
        </Box>
    )
}

import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { UserAuth, loading, login, logout } from "./slice";

export const checkingAuth = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
    }
}
export const setUser = (user: UserAuth): ThunkAction<void, RootState, unknown, AnyAction> => {
    console.log("user",user)
    return async (dispatch) => {
        dispatch(login(user));
    }
}


export const setUpdate = (user: any): ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch) => {
        // console.log(user, 'holaaaaaaa');
        
        dispatch(login(user.result));
    }
}
export const startLogout = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
        dispatch(logout())
    }
}

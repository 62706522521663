import React from 'react'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import GamesIcon from '@mui/icons-material/Games';
import PaymentsIcon from '@mui/icons-material/Payments';
import { Box, Divider, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

export const CustomDrawer = () => {
    const location = useLocation()
    const path = location.pathname

    const linkList = [
        {
            name: "Inicio",
            path: "/",
            icon: AccountCircleIcon
        },
        {
            name: "Información Personal",
            path: "/personal-info",
            icon: AssignmentIndIcon
        },
        {
            name: "Transacciones",
            path: "/transactions",
            icon: PaymentsIcon
        },
        {
            name: "Games Ground",
            path: "/gamesground",
            icon: GamesIcon
        }
    ]
    return (
        <Box
            className="drawer-container"
            sx={{
                width: { md: "20%" },

                pt: 2,
                "a": {
                    color: "#000",
                    textDecoration: "none"
                },
                borderRight: "solid rgb(218,220,224) 1px"
            }}
        >
            {/* Icon Tesseract */}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Box
                    sx={{
                        width: 150,
                        my: 1,
                        "img": {
                            width: "100%"
                        },
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <img src="https://tesseractwebresources.blob.core.windows.net/webimages/Logo/Tesseract Logo.png" alt="Tesseract Logo" />
                </Box>
            </Box>
            <Divider />

            {
                linkList.map((item) => {
                    return (
                        <Link to={item.path}>
                            <Box
                                sx={{
                                    pl: 2,
                                    py: 1,
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                    backgroundColor: item.path === path ? `rgba( 255, 164, 0, 0.24) !important` : "",
                                }}
                            >
                                {/* Icon */}
                                <item.icon sx={{ color: "#ffa400", fontSize: 35 }} />
                                <Typography>
                                    {item.name}
                                </Typography>

                            </Box>
                        </Link>
                    )
                })
            }
        </Box>
    )
}

import { Box, Divider, Grid } from '@mui/material'
import React from 'react'

interface Props {
    name: string
    quatity: number
    price: number
}
export const TableItem = ({ name, quatity, price }: Props) => {
    return (
        <Box
            sx={{ cursor: "pointer" }}>
            <Grid container spacing={2}>
                <Grid item md={2}>
                    {name}
                </Grid>
                <Grid item md={8}
                    sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap"
                    }}
                >
                    {quatity}
                </Grid>
                <Grid item md={2}>
                    ${price}
                </Grid>
            </Grid>
            <Divider sx={{ my: 1 }} />
        </Box>

    )
}

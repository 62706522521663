import { Box, Divider, Grid, List, ListItem, Paper, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import { getUserTransactions } from '../../redux/transactions/thunk'
import zIndex from '@mui/material/styles/zIndex'
import moment from 'moment'
import { TransactionList } from './TransactionList'
import { TransactionListMobil } from './TransactionListMobil'

export const Transactions = () => {
    const { id } = useAppSelector(state => state.auth)
    const { transactions } = useAppSelector(state => state.transactions)

    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(getUserTransactions(id))
    }, [])

    return (
        <Box
            width={'100%'}
        >
            <Paper
                elevation={4}
                sx={{
                    // width: "100%",
                    p: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    backgroundColor: "#f5f5f5"
                }}
            >
                <Typography variant='h5' fontWeight={400}>
                    Transacciones
                </Typography>


                <TransactionList transactions={transactions} />
                <TransactionListMobil transactions={transactions} />

            </Paper>
        </Box>
    )
}

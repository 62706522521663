import { createSlice } from "@reduxjs/toolkit";

export interface UserAuth {
    sub: string,
    email: string,
    token: string,
    roles: string,
    family_name: string,
    birthdate: string,
    uid: string
    profileImage: string
    username: string
}
export interface UserGG {
    userName: string
    experience: number,
    level: number,
    currentSkin: string,
    inventory: string,
    ggCoins: number,
    bits: number,
    crystals: number
}
export interface Transactions {
    name: string
    price: number
    quatity: number
}
export interface GGStore {
    status: string,
    userName: string
    experience: number,
    level: number,
    currentSkin: string,
    inventory: string,
    ggCoins: number,
    bits: number,
    crystals: number
    transactions: Transactions[]

}
const initialState: GGStore = {
    status: "loading",
    "userName": "",
    "experience": 0,
    "level": 0,
    "currentSkin": "",
    "inventory": "",
    "ggCoins": 0,
    "bits": 0,
    "crystals": 0,
    transactions: []
}
export const ggDataSlice = createSlice({
    name: 'ggData',
    initialState,
    reducers: {
        getData: (state, { payload }) => {
            console.log(payload);
            state.status = "loaded"
            // state.news = payload.data.news
            state.userName = payload.userName;
            state.experience = payload.experience;
            state.level = payload.level
            state.currentSkin = payload.currentSkin;
            state.inventory = payload.inventory;
            state.ggCoins = payload.ggCoins;
            state.bits = payload.bits
            state.crystals = payload.crystals
        },
        getTransactions: (state, { payload }) => {
            console.log(payload);
            state.status = "loaded"
            state.transactions = payload.transactions
        },
        loading: (state) => {
            state.status = "loading"
        },

    }
})
export const { getData, loading, getTransactions } = ggDataSlice.actions

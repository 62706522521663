import React, { ChangeEvent, useState } from 'react'
import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, MenuItem, Paper, TextField, Typography } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { stringAvatar } from '../../functions/stringAvatar'
import EditIcon from '@mui/icons-material/Edit';
import { UploadFileButton } from '../../custom/UploadFileButton';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { EditableOption } from '../../custom/EditableOption';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import moment from 'moment';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { setUpdate } from '../../redux/auth/thunk';
export interface userBasicInfo {
    name: string
    birthDate: null | Dayjs
}
export const BasicInfo = () => {
    const dispatch = useAppDispatch()
    const { name, lastname, birthdate, id, profileImage } = useAppSelector(state => state.auth)

    const [user, setUser] = React.useState<userBasicInfo>({
        name: "",
        birthDate: dayjs(),
    })
    const [open, setOpen] = React.useState(false);
    const [openPhoto, setOpenPhoto] = React.useState(false);
    const [imageSrc, setImageSrc] = React.useState('');
    const [birthdateError, setBirthdateError] = React.useState({ state: false, message: "" })

    const [mainImage, setMainImage] = useState<File>()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpenPhoto = () => {
        setOpenPhoto(true);
    };

    const handleClosePhoto = () => {
        setOpenPhoto(false);
        setMainImage(undefined)
    };

    const FileClear = () => {
        setMainImage(undefined)
    }
    const FileSelector = (file: File | undefined) => {
        setMainImage(file)
    }

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setUser({ ...user, [name]: value })
        setBirthdateError({ state: false, message: "" })
    }
    const handleSubmit = async (value: string | File, name: string) => {
        const token = window.localStorage.getItem('jwt') as string;
        const currentDate = new Date(birthdate);
        const utcDate = currentDate.toISOString();
        let user: any = {
            name: "",
            lastName: "",
            birthDate: utcDate,
            userName: "",
            email: "",
            phoneNumber: ""
        }
        user[name] = value
        console.log(user);

        const url = process.env.REACT_APP_WEBMANAGER as string
        try {
            const response = await axios.put(`${url}/api/account/updateuser?Id=${id}`, user, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })

            if (response) {
                if (user) {

                    axios.get(`${process.env.REACT_APP_WEBMANAGER}/api/account/information`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                        .then(response => {
                            dispatch(setUpdate({ ...response.data, id: id }))

                        })
                        .catch((error): any => {
                            console.error("asdasd", error);
                        });

                }
                return true
            } else {
                return false
            }
        } catch (error) {
            return false

        }



    }

    const handleSubmitPhoto = async (value: string | File, name: string) => {
        const token = window.localStorage.getItem('jwt') as string;
        const data = new FormData()
        data.append('file', value);

        const url = process.env.REACT_APP_WEBMANAGER as string
        try {
            const response = await axios.post(`${url}/api/account/image`, data, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })

            if (response) {
                if (user) {
                    axios.get(`${process.env.REACT_APP_WEBMANAGER}/api/account/information`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                        .then(response => {
                            dispatch(setUpdate({ ...response.data, id: id }))
                        })
                        .catch((error): any => {
                            console.error(error);
                        });

                }
                return true
            } else {
                return false
            }
        } catch (error) {
            return false

        }
    }

    React.useEffect(() => {
        if (profileImage !== undefined) {

            const blob = new Blob([profileImage], { type: 'image/jpeg' });
            const imageUrl = URL.createObjectURL(blob);
            console.log(imageUrl);

            setImageSrc(imageUrl)
        }
    }, [profileImage])
    return (
        <div>
            <Box
                sx={{
                    border: "solid rgb(218,220,224) 1px",
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "column",
                    width: "90%",
                    margin: "0 auto",
                }}
            >
                <Box p={2}>
                    <Typography variant='h5'>
                        Informacion Basica
                    </Typography>
                    {/* <Typography variant='body2'>
                        Es posible que otros usuarios puedan ver parte de la información al usar los servicios de Google. Más información
                    </Typography> */}
                </Box>

                <EditableOption label='Foto' name='photo' value={`${profileImage}`} type='image' onSubmit={handleSubmitPhoto} />
                <Divider />

                <EditableOption label='Nombre' name='name' value={`${name}`} type='text' onSubmit={handleSubmit} />
                <Divider />
                <EditableOption label='Apellido' name='lastName' value={`${lastname}`} type='text' onSubmit={handleSubmit} />
                <Divider />

                <EditableOption label='Fecha de nacimiento' name='birthdate' value={moment(birthdate).format("DD/MM/YYYY")} type='date' onSubmit={handleSubmit} />
                <Divider />

            </Box>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle> Informacion Basica</DialogTitle>
                <DialogContent>
                    {/* <DialogContentText>
                        To subscribe to this website, please enter your email address here. We
                        will send updates occasionally.
                    </DialogContentText> */}
                    <TextField
                        autoFocus
                        // margin="dense"
                        id="name"
                        label="Nombre"
                        type="name"
                        fullWidth
                        variant="standard"
                    />
                    <DatePicker
                        label="Fecha de nacimiento"
                        value={user.birthDate}
                        onChange={
                            (value) => {
                                setUser({ ...user, birthDate: value })
                                setBirthdateError({ state: false, message: "" })
                            }
                        }
                        disableFuture
                        slotProps={{
                            textField: {
                                helperText: birthdateError.message,
                                color: birthdateError && 'error',
                                focused: birthdateError.state

                            }
                        }}
                        // onError={() => { setBirthdateError({ state: true, message: "Fecha no permitida" }) }}
                        sx={{ mt: 6, width: "100%" }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={handleClose}>Cambiar información</Button>
                </DialogActions>
            </Dialog>


        </div>
    )
}

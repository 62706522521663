import { createSlice } from "@reduxjs/toolkit"

export interface Product {
    id: string
    itemId: string
    price: number
    quantity: number
}
export interface Transactions {
    id: string
    paymentId: string
    status: string
    price: number
    paymentMethod: string
    createdAt: string
    Products: Product[],
}
export interface TransactionsStore {
    status: string,
    transactions: Transactions[]

}
const initialState: TransactionsStore = {
    status: "loading",
    transactions: []
}
export const transactionsSlices = createSlice({
    name: 'transactions',
    initialState,
    reducers: {
        getTransactions: (state, { payload }) => {
            state.status = "loaded"
            state.transactions = payload
        },
        loading: (state) => {
            state.status = "loading"
        },

    }
})
export const { loading, getTransactions } = transactionsSlices.actions

import React, { ChangeEvent, useState } from 'react'
import { Box } from '@mui/material'
import { BasicInfo } from './BasicInfo'
import { AdvanceInfo } from './AdvanceInfo'



export const PersonalInfoPage = () => {

    return (
        <Box
            sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: 4
            }}
        >
            <BasicInfo />
            <AdvanceInfo />
        </Box>
    )
}

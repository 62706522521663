import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/hooks";
import { setUser } from "../../redux/auth/thunk";
import { parseJwt } from "../../functions/DecodeJWT";
import axios from "axios";
export const LoginPage = () => {
    const dispatch = useAppDispatch()
    const location = useLocation();
    const navigate = useNavigate()
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('jwtToken') as string;


    const user = parseJwt(token)
    useEffect(() => {
        if (user) {

            axios.get(`${process.env.REACT_APP_WEBMANAGER}/api/account/information`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(response => {
                    console.log(response.data);

                    dispatch(setUser({ ...response.data.result, id: user.uid }))
                    navigate("/")
                })
                .catch((error): any => {
                    console.error(error);
                    navigate("/login")
                });

        } else {
            // navigate("/error")
        }
    }, [])
    useEffect(() => {
        if (token) {
            window.localStorage.setItem("jwt", token);
        }
    }, [token])

    return (
        <div>
            {/* <LoginForm /> */}
        </div>
    )
}

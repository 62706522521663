import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { loading, getTransactions } from "./slice";
import agent from "./agent";

export const getUserTransactions = (userId: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(loading());
        agent.getPayments(userId)
            .then((result) => {
                dispatch(getTransactions(result.data.transaction))
            }).catch((err) => {
                console.log(err);

            });
    }
}

import { createSlice } from "@reduxjs/toolkit";

export interface UserAuth {
    sub: string,
    email: string,
    token: string,
    roles: string,
    family_name: string,
    birthdate: string,
    uid: string
    profileImage: string
    username: string

}
export interface User {
    status: string,
    id: string,
    token: string | null,
    email: string,
    name: string,
    lastname: string
    birthdate: string,
    roles: string,
    profileImage: string
    username: string
}
export interface UserStore {
    status: string,
    id: string,
    token: string | null,
    email: string,
    name: string,
    lastname: string
    // photoURL: string,
    // errorMessage: string | null,
    birthdate: string,
    roles: string[],
    profileImage: string
    username: string

}
const initialState: UserStore = {
    status: "loading",
    id: "",
    token: null,
    email: "",
    name: "",
    lastname: "",
    birthdate: "",
    roles: [],
    profileImage: "",
    username: ""
}
export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state, { payload }) => {
            console.log(payload);
            state.status = "logged"
            // state.news = payload.data.news
            state.token = payload.token;
            state.email = payload.email;
            state.id = payload.id
            state.name = payload.name;
            state.lastname = payload.lastName;
            if (typeof payload.roles == "string") {
                state.roles = [payload.roles]
            } else
                state.roles = payload.roles;
            state.birthdate = payload.birthDate;
            state.profileImage = payload.profilePicture
            state.username = payload.username
        },
        loading: (state) => {
            state.status = "loading"
        },
        logout: (state) => {
            state.status = "notLogged";
            state.token = "";
            state.email = "";
            state.name = "";
            state.lastname = "";
            state.roles = [];
            state.birthdate = "";
        },
    }
})
export const { login, loading, logout } = authSlice.actions

import { Avatar, Box, LinearProgress, Paper, ThemeProvider, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { stringAvatar } from '../../functions/stringAvatar'
import { appTheme } from "../../style/AppTheme"
import { TableItem } from './tableItem'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import { getUserGG } from '../../redux/gamesground/thunk'

export const GamesGroundPage = () => {
    const { id, profileImage, } = useAppSelector(state => state.auth)
    const { userName, level, transactions } = useAppSelector(state => state.ggData)

    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(getUserGG(id))
    }, [])

    return (
        <ThemeProvider theme={appTheme}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: "100%",
                    gap: 4
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        height: 300,
                        alignItems: "center",
                        flexDirection: "column",
                        gap: 1
                    }}
                >
                    <Avatar sx={{ width: 100, height: 100, fontSize: 30 }} src={`${profileImage}`} />
                    <Typography variant='h5'>
                        ¡Bienvenido, {userName}!
                    </Typography>
                    <Typography variant='h5'>
                        Lvl. {level}
                    </Typography>
                    <Box sx={{ width: "40%" }}>
                        <LinearProgress variant="buffer" value={level} color='primary' />
                    </Box>
                </Box>

                <Box height={1000}>
                    <Box
                        sx={{
                            border: "solid rgb(218,220,224) 1px",
                            borderRadius: "8px",
                            display: "flex",
                            flexDirection: "column",
                            width: "90%",
                            margin: "0 auto",
                            ".option-container": {
                                cursor: "pointer",
                                px: 2,
                                height: 65
                            },
                            ".option-container:hover": {
                                backgroundColor: "rgba(0,0,0,0.1)"
                            }
                        }}>
                        <Box p={2}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 2
                            }}
                        >
                            <Typography variant='h5'>
                                Aspectos y transacciones
                            </Typography>
                            {
                                transactions.map((item, index) => {
                                    return (
                                        <TableItem
                                            name={item.name}
                                            quatity={item.quatity}
                                            price={item.price}
                                        />
                                    )
                                })
                            }


                        </Box>
                    </Box>
                </Box>

            </Box>
        </ThemeProvider>

    )
}

import React from 'react'
import { Box, Divider, Grid, List, ListItem, Paper, Typography } from '@mui/material'
import { Transactions } from '../../redux/transactions/slice'
import moment from 'moment'

interface Props {
    transactions: Transactions[]
}
export const TransactionList = ({ transactions }: Props) => {
    return (
        <Box
            display={{ xs: "none", md: "block" }}
        >
            <Box
                sx={{ cursor: "pointer" }}>
                <Grid container spacing={2}>
                    <Grid item md={1.5}
                        sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap"
                        }}
                    >
                        ID transacción
                    </Grid>
                    <Grid item md={1.5}
                        sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap"
                        }}
                    >
                        Estado
                    </Grid>
                    <Grid item md={1}>
                        Metodo de pago
                    </Grid>
                    <Grid item md={5} textAlign={"center"}>
                        Listado de productos
                    </Grid>
                    <Grid item md={2}>
                        Fecha de compra
                    </Grid>
                    <Grid item md={1}>
                        Precio total
                    </Grid>
                </Grid>
                <Divider sx={{ my: 1 }} />
            </Box>
            {
                transactions.map((transaction, zIndex) => {
                    return (
                        <Box
                            key={zIndex}
                            sx={{ cursor: "pointer" }}>
                            <Grid container spacing={2}>
                                <Grid item md={1.5}
                                    sx={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap"
                                    }}
                                >
                                    {transaction.paymentId}
                                </Grid>
                                <Grid item md={1.5}
                                    sx={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap"
                                    }}
                                >
                                    {transaction.status.toUpperCase()}
                                </Grid>
                                <Grid item md={1}>
                                    {transaction.paymentMethod.toUpperCase()}
                                </Grid>
                                <Grid item md={5}>
                                    <List
                                        sx={{
                                            maxHeight: 100,
                                            overflow: 'auto',
                                            p: 0,

                                        }}
                                    >
                                        {
                                            transaction.Products.map((product, zIndex) =>
                                                <ListItem
                                                    sx={{
                                                        p: 0,
                                                        flexDirection: 'column',
                                                        width: "100%",

                                                    }}
                                                >
                                                    <ul>
                                                        <li>
                                                            Item ID: {product.itemId}
                                                        </li>
                                                        <li>
                                                            Cantidad: {product.quantity}
                                                        </li>
                                                        <li>
                                                            Precio producto: {product.price}
                                                        </li>
                                                    </ul>
                                                </ListItem>

                                            )
                                        }
                                    </List>
                                </Grid>
                                <Grid item md={2}>
                                    {moment(transaction.createdAt).format("DD MMMM YYYY")}
                                </Grid>
                                <Grid item md={1}>
                                    ${transaction.price}
                                </Grid>
                            </Grid>
                            <Divider sx={{ my: 1 }} />
                        </Box>
                    )
                })
            }
        </Box>
    )
}

import axios, { AxiosResponse } from 'axios';

const url = process.env.REACT_APP_GGBACK_URL as string

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

axios.interceptors.request.use(config => {
    const token = window.localStorage.getItem("jwt");
    if (token) {
        config.headers!['Authorization'] = `Bearer ${token}`
    }
    return config
})
const requests = {
    get: <T>(url: string) => axios.get<T>(url).then(responseBody),
    post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
}
const GGAgent = {
    getByID: (id: string) => requests.get<any>(url + "/api/player/" + id),
    getPayments: (id: string) => requests.del<any>(url + "/api/pay/" + id),


}
export default GGAgent
import { Avatar, Box, Typography } from '@mui/material'
import React from 'react'
import { stringAvatar } from '../../functions/stringAvatar'
import { useAppSelector } from '../../hooks/hooks'



export const Home = () => {
    const { name, lastname, profileImage } = useAppSelector(state => state.auth)
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "100%"
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    height: 300,
                    alignItems: "center",
                    flexDirection: "column",
                    gap: 1
                }}
            >
                <Avatar {...stringAvatar(`${name} ${lastname}`)} src={`${profileImage}`} sx={{ width: 100, height: 100, fontSize: 30 }} />
                <Typography variant='h5'>
                    ¡Bienvenido, {name} {lastname}
                </Typography>
            </Box>

            <Box height={1000}>

            </Box>

        </Box>
    )
}

import React, { ChangeEvent, FormEvent, useState } from 'react'
import { FormControl, InputLabel, Input, InputAdornment, IconButton, Avatar, FormHelperText, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, MenuItem, Paper, TextField, Typography } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { stringAvatar } from '../../functions/stringAvatar'
import { VisibilityOff, Visibility } from '@mui/icons-material'
import EditIcon from '@mui/icons-material/Edit';
import { isPassword } from '../../functions/PasswordVerificator';
import { isEmail } from '../../functions/EmailVerificator';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { EditableOption } from '../../custom/EditableOption';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { setUpdate } from '../../redux/auth/thunk';
import Swal from 'sweetalert2';

export interface PasswordError {
    state: boolean
    message: string | React.ReactNode
}
export interface userAdvanceInfo {
    userName: string
    email: string
    phoneNumber: string

}
export const AdvanceInfo = () => {
    const { email, id, birthdate, username } = useAppSelector(state => state.auth)
    const dispatch = useAppDispatch()
    const [user, setUser] = React.useState<userAdvanceInfo>({
        userName: "",
        email: "",
        phoneNumber: ""
    })

    const [passwords, setPasswords] = React.useState({
        currentPassword: "",
        newPass: "",
        ConfirmNewPass: ""
    })
    const [open, setOpen] = React.useState(false);
    const [openPass, setOpenPass] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);
    const [passwordError, setPasswordError] = useState<PasswordError>({ state: false, message: "" })
    const [NewpasswordError, setNewPasswordError] = useState<PasswordError>({ state: false, message: "" })
    const [requestState, setRequestState] = useState({ state: "initial", message: "" })

    const [confError, setConfError] = useState({ state: false, message: "" })
    const [emailError, setEmailError] = useState({ state: false, message: "" })
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleClickOpenPass = () => {
        setOpenPass(true);
    };

    const handleClosePass = () => {
        setOpenPass(false);
    };


    const handleInputChangePasswords = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setPasswords({ ...passwords, [name]: value })
        setPasswordError({ state: false, message: "" })
        setNewPasswordError({ state: false, message: "" })
        setConfError({ state: false, message: "" })
    }

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };


    const handleSubmitAdvanceInfo = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        if (!isEmail(user.email)) {
            setEmailError({
                state: true, message: "Por favor ingrese un correo valido."
            })
        }

    }
    const handleSubmitPasswordChange = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (!isPassword(passwords.newPass)) {
            // setPasswordError({
            //     state: true, message: "La contraseña debe contener al menos 8 caracteres, contener mayúsculas y minúsculas, contener al menos un número y un carácter especial"
            // })
            setNewPasswordError({
                state: true, message:
                    <div>
                        La contraseña debe contener:
                        <ul>
                            <li>Al menos 8 caracteres</li>
                            <li>Una mayúscula y minúsculas</li>
                            <li>Al menos un número y un carácter especial</li>
                        </ul>
                    </div>
            })
        }
        else if (passwords.newPass !== passwords.ConfirmNewPass) {
            setConfError({
                state: true, message: "Las contraseñas no coinciden, por favor revise que sean identicas."
            })
        }
        else if (!passwordError.state && !NewpasswordError.state && !confError.state) {
            console.log(passwordError, NewpasswordError, confError);

            setRequestState({ ...requestState, state: "loading" })
            const passwordsData = {
                id: id,
                password: passwords.currentPassword,
                newPassword: passwords.newPass,
                confirmPassword: passwords.ConfirmNewPass
            }
            const url = process.env.REACT_APP_WEBMANAGER as string
            const token = window.localStorage.getItem('jwt') as string;
            console.log(token);

            axios.post(`${process.env.REACT_APP_TAUTH_URL as string}/api/account/changepassword`, passwordsData, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then((result) => {
                    axios.get(`${process.env.REACT_APP_WEBMANAGER}/api/account/information`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                        .then(response => {
                            dispatch(setUpdate({ ...response.data, id: id }))
                            console.log(response);
                            setRequestState({ ...requestState, state: "" })
                            handleClosePass()
                            Swal.fire({
                                icon: 'success',
                                title: 'Datos actualizado con exito',
                                showConfirmButton: false,
                                timer: 1500
                            })
                        })
                        .catch((error): any => {
                            console.error(error);
                            setRequestState({ ...requestState, state: "" })
                        });


                }).catch((err) => {
                    setRequestState({ ...requestState, state: "" })
                    setPasswordError({
                        state: true, message: "Algo salio mal, favor revise la contraseña ingresada."
                    })
                });
        }

    }
    const handleSubmit = async (value: string | File, name: string) => {
        const token = queryParams.get('jwtToken') as string;
        const currentDate = new Date(birthdate);
        const utcDate = currentDate.toISOString();
        let user: any = {
            name: "",
            lastName: "",
            birthDate: utcDate,
            userName: "",
            email: "",
            phoneNumber: ""
        }
        user[name] = value
        console.log(user);

        const url = process.env.REACT_APP_WEBMANAGER as string
        try {
            const response = await axios.put(`${url}/api/account/updateuser?Id=${id}`, user, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            console.log(response);

            if (response) {
                if (user) {

                    axios.get(`${process.env.REACT_APP_WEBMANAGER}/api/account/information`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                        .then(response => {
                            dispatch(setUpdate({ ...response.data, id: id }))
                            console.log(response);

                        })
                        .catch((error): any => {
                            console.error(error);
                        });

                } else {
                }
                return true
            } else {
                return false
            }
        } catch (error) {
            return false

        }



    }


    return (
        <div>
            <Box
                sx={{
                    border: "solid rgb(218,220,224) 1px",
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "column",
                    width: "90%",
                    margin: "0 auto",
                    ".option-container": {
                        cursor: "pointer",
                        px: 2,
                        height: 65
                    },
                    ".option-container:hover": {
                        backgroundColor: "rgba(0,0,0,0.1)"
                    }
                }}
            >
                <Box p={2}>
                    <Typography variant='h5'>
                        Informacion Avanzada
                    </Typography>

                </Box>
                <EditableOption label='Nombre de usuario' name='username' value={username} type='text' onSubmit={handleSubmit} />
                <Divider />
                <EditableOption label='Correo' name='email' value={email} type='text' onSubmit={handleSubmit} />
                <Divider />

                {/* <Grid container alignItems={"center"} className='option-container' onClick={handleClickOpenPass}>
                    <Grid item xs={3}>
                        <Typography sx={{ color: "rgb(95,99,104)", fontWeight: "500" }}>
                            Contraseña
                        </Typography>
                    </Grid>
                    <Grid item xs={9} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography sx={{ color: "#000", fontWeight: "400" }}>
                            ••••••••••••
                        </Typography>
                        <EditIcon sx={{ color: "rgb(95,99,104)" }} />
                    </Grid>
                </Grid> */}
            </Box>

            <Dialog open={openPass} onClose={handleClosePass}>
                <DialogTitle> Cambio de contraseña</DialogTitle>
                <DialogContent>

                    <Box component={"form"} noValidate={false} sx={{ px: 4, display: 'flex', flexDirection: 'column', gap: 2 }} onSubmit={handleSubmitPasswordChange}>
                        <FormControl sx={{ width: "100%", my: 1 }} variant="standard">
                            <InputLabel required
                                color={passwordError.state ? "error" : "primary"}
                                focused={passwordError.state}
                            >
                                Contraseña actual
                            </InputLabel>
                            <Input
                                required
                                onChange={handleInputChangePasswords}
                                name='currentPassword'
                                // error={passwordError.state}
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <FormHelperText
                                className={passwordError.state ? 'Mui-error' : ""}
                            >
                                {passwordError.message}
                            </FormHelperText>
                        </FormControl>

                        <FormControl sx={{ width: "100%", my: 1 }} variant="standard">
                            <InputLabel required
                                color={NewpasswordError.state ? "error" : "primary"}
                                focused={NewpasswordError.state}>
                                Contraseña
                            </InputLabel>
                            <Input
                                required
                                onChange={handleInputChangePasswords}
                                name='newPass'
                                error={NewpasswordError.state}
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <FormHelperText
                                className={NewpasswordError.state ? 'Mui-error' : ""}
                            >
                                {NewpasswordError.message}
                            </FormHelperText>
                        </FormControl>

                        <FormControl sx={{ width: "100%", my: 1 }} variant="standard">
                            <InputLabel required
                                color={confError.state ? "error" : "primary"}
                                focused={confError.state}
                            >
                                Confirmar contraseña
                            </InputLabel>
                            <Input
                                required
                                onChange={handleInputChangePasswords}
                                name='ConfirmNewPass'
                                error={confError.state}
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <FormHelperText className={passwordError.state ? 'Mui-error' : ""} >{confError.message}</FormHelperText>
                        </FormControl>
                        <DialogActions>
                            <Button onClick={handleClosePass} disabled={requestState.state === "loading"}>Cancelar</Button>
                            <Button type='submit' disabled={requestState.state === "loading"}>Cambiar contraseña</Button>
                        </DialogActions>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    )
}

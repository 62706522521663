import { Avatar, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import { stringAvatar } from '../functions/stringAvatar';
import { UploadFileButton } from './UploadFileButton';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';
import Swal from 'sweetalert2';


interface Props {
    name: string
    value: string
    label: string
    type: 'text' | "image" | "date"
    onSubmit: (value: string | File, name: string) => Promise<boolean>
}
export const EditableOption = ({ name, value, label, type, onSubmit }: Props) => {
    const [editable, setEditable] = useState(false)
    const [date, setDate] = useState<Dayjs | null>(dayjs(value))
    const [textValue, setTextValue] = useState(value)

    const [openPhoto, setOpenPhoto] = React.useState(false);
    const [mainImage, setMainImage] = useState<File>()
    const [dateError, setDateError] = React.useState({ state: false, message: "" })
    const [reqStatus, setReqStatus] = useState({ state: "", message: "" })

    const handleSubmit = async (value: any) => {
        setReqStatus({ state: "loading", message: "" })
        let response = await onSubmit(value, name)
        if (response) {
            setReqStatus({ state: "success", message: "" })
            setEditable(false)
            setOpenPhoto(false)
            Swal.fire({
                icon: 'success',
                title: 'Datos actualizado con exito',
                showConfirmButton: false,
                timer: 1500
            })
        } else {
            setReqStatus({ state: "error", message: "" })
            Swal.fire({
                icon: 'error',
                title: 'Algo salio mal, revise los datos ingresados e intentelo nuevamente.',
                showConfirmButton: false,
                timer: 1500
            })
        }
    }
    if (type == 'text')
        return (
            <Grid container alignItems={"center"} className='option-container'
                sx={{
                    // cursor: "pointer",
                    px: 2,
                    height: 65,
                    ":hover": { backgroundColor: "rgba(0,0,0,0.1)" }
                }}
            >
                <Grid item xs={3}>
                    <Typography sx={{ color: "rgb(95,99,104)", fontWeight: "500" }}>
                        {label}
                    </Typography>
                </Grid>
                <Grid item xs={9} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                    {
                        editable ?
                            <Box display={"flex"} width={"100%"} justifyContent={"space-between"} alignItems={"center"}>
                                <Box>
                                    <TextField
                                        autoFocus
                                        // margin="dense"
                                        name={name}
                                        id={name}
                                        label={label}
                                        fullWidth
                                        variant="standard"
                                        value={textValue}
                                        onChange={(event) => setTextValue(event.target.value)}
                                        error={reqStatus.state === 'error'}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        gap: 2
                                    }}
                                >
                                    {reqStatus.state === 'loading' && <CircularProgress />}
                                    {(reqStatus.state === '' || reqStatus.state === 'error') &&

                                        <>
                                            <CloseIcon color='error' onClick={() => setEditable(false)} />
                                            <CheckIcon color='success' onClick={() => handleSubmit(textValue)} />
                                        </>
                                    }


                                </Box>
                            </Box>
                            :
                            <Box display={"flex"} width={"100%"} justifyContent={"space-between"} alignItems={"center"}>
                                <Typography sx={{ color: "#000", fontWeight: "400" }}>
                                    {value}
                                </Typography>
                                <EditIcon sx={{ color: "rgb(95,99,104)" }} onClick={() => {
                                    setEditable(true)
                                    setReqStatus({ state: "", message: "" })
                                    setTextValue(value)
                                }} />
                            </Box>
                    }

                </Grid>
            </Grid>
        )

    else if (type == 'image') {


        const handleClickOpenPhoto = () => {
            setOpenPhoto(true);
        };

        const handleClosePhoto = () => {
            setOpenPhoto(false);
            setMainImage(undefined)
        };

        const FileClear = () => {
            setMainImage(undefined)
        }
        const FileSelector = (file: File | undefined) => {
            setMainImage(file)
        }
        return (
            <>
                <Grid container alignItems={"center"} className='option-container' sx={{
                    // cursor: "pointer",
                    px: 2,
                    height: 65,
                    ":hover": { backgroundColor: "rgba(0,0,0,0.1)" }
                }} onClick={handleClickOpenPhoto}>
                    <Grid item xs={3}>
                        <Typography sx={{ color: "rgb(95,99,104)", fontWeight: "500" }}>
                            {label}
                        </Typography>
                    </Grid>
                    <Grid item xs={9} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography sx={{ color: "rgb(95,99,104)", fontWeight: "500", fontSize: 12 }}>
                            Una foto que ayuda a personalizar tu cuenta
                        </Typography>
                        <Avatar sx={{ width: 50, height: 50, fontSize: 13 }} src={`${value}`} />
                    </Grid>
                </Grid>
                <Dialog open={openPhoto} onClose={handleClosePhoto}>
                    <DialogTitle>Cambiar foto</DialogTitle>
                    <DialogContent>
                        {
                            mainImage &&
                            <Box
                                sx={{ width: "50%" }}
                            >
                                <Avatar {...stringAvatar('Simon Navarrete')} sx={{ width: 150, height: 150, fontSize: 13 }} src={URL.createObjectURL(mainImage)} />

                            </Box>
                        }
                        <Box my={2}>
                            <UploadFileButton label='Imagen de Perfil' FileClear={FileClear} FileSelector={FileSelector} file={mainImage}></UploadFileButton>
                        </Box>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClosePhoto}>Cancelar</Button>
                        <Button onClick={() => handleSubmit(mainImage)}>Cambiar imagen</Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }
    else if (type == 'date') {
        return (
            <Grid container alignItems={"center"} className='option-container' sx={{
                // cursor: "pointer",
                px: 2,
                height: 65,
                ":hover": { backgroundColor: "rgba(0,0,0,0.1)" }
            }}>
                <Grid item xs={3}>
                    <Typography sx={{ color: "rgb(95,99,104)", fontWeight: "500" }}>
                        {label}
                    </Typography>
                </Grid>
                <Grid item xs={9} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                    {
                        editable ?
                            <Box display={"flex"} width={"100%"} justifyContent={"space-between"} alignItems={"center"}>
                                <Box>
                                    <DatePicker
                                        label={label}
                                        value={date}
                                        onChange={
                                            (value) => {
                                                setDate(value)
                                                setDateError({ state: false, message: "" })
                                            }
                                        }
                                        disableFuture
                                        slotProps={{
                                            textField: {
                                                helperText: dateError.message,
                                                color: dateError && 'error',
                                            }
                                        }}
                                        sx={{ width: "100%" }}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        gap: 2
                                    }}
                                >
                                    {reqStatus.state === 'loading' && <CircularProgress />}
                                    {(reqStatus.state === '' || reqStatus.state === 'error') &&

                                        <>
                                            <CloseIcon color='error' onClick={() => setEditable(false)} />
                                            <CheckIcon color='success' onClick={() => handleSubmit(date)} />
                                        </>
                                    }

                                </Box>
                            </Box>
                            :
                            <Box display={"flex"} width={"100%"} justifyContent={"space-between"} alignItems={"center"}>
                                <Typography sx={{ color: "#000", fontWeight: "400" }}>
                                    {value}
                                </Typography>
                                <EditIcon sx={{ color: "rgb(95,99,104)" }} onClick={() => setEditable(true)} />
                            </Box>
                    }
                </Grid>
            </Grid>
        )
    }

    else {
        return (<></>)
    }

}

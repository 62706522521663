import { Box, Paper, Toolbar } from '@mui/material'
import React, { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { LoginPage } from '../pages/Login/VerifyLogin'
import { useAppDispatch, useAppSelector } from '../hooks/hooks'
import { checkingAuth, startLogout } from '../redux/auth/thunk'
import { CustomDrawer } from '../custom/drawer'
import { Home } from '../pages/Home'
import { PersonalInfoPage } from '../pages/PersonalInfo'
import { GamesGroundPage } from '../pages/GamesGround'
import { Transactions } from '../pages/Transactions'


export const AppRouter = () => {
    const dispatch = useAppDispatch()
    const token = window.localStorage.getItem("jwt");
    const { status } = useAppSelector(state => state.auth)
    const location = useLocation()

    // useEffect(() => {
    //     const queryParams = new URLSearchParams(location.search);
    //     const tokenParam = queryParams.get('jwtToken') as string;
    //     dispatch(checkingAuth());
    //     if (status === "checking" && token && !tokenParam) {
    //         window.location.href = `${process.env.REACT_APP_TAUTHWEB}/?url=${window.location.origin}`
    //     } else {
    //         dispatch(startLogout());
    //     }
    // }, []);
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const tokenParam = queryParams.get('jwtToken') as string;
        if (status !== "logged" && !tokenParam) {
            window.location.href = `${process.env.REACT_APP_TAUTHWEB}/?url=${window.location.origin}`
        }
    }, [status])

    if (status !== "logged") {
        return (
            <Box
                sx={{
                    backgroundColor: "rgb(31,41,55)",
                    minHeight: "100vh",
                    color: '#fff'
                }}
            >
                <Routes>
                    <Route path={`/redirect-login`} element={<LoginPage />} />
                    <Route path={`/*`} element={<Box>Acc not Allowed</Box>} />

                </Routes >
            </Box>
        )
    }
    else {
        return (
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "100vh",
                    backgroundImage: "linear-gradient(to right bottom, #ffa400, #ffa284, #ffbdd6, #ffe2ff, #ffffff)",
                }}
            >

                <Paper elevation={6} sx={{
                    width: { md: "80%", sm: "100%", xs: "95vw" }, height: "80vh", display: "flex", ":-khtml-any-link.drawer-container": { height: "80vh" },

                }} >
                    <CustomDrawer />
                    <Box p={2} display={"flex"} width={"100%"} sx={{
                        overflowY: "scroll"
                    }}>
                        <Routes>
                            <Route path={`/`} element={<Home></Home>} />
                            <Route path={`/personal-info`} element={<PersonalInfoPage></PersonalInfoPage>} />
                            <Route path={`/gamesground`} element={<GamesGroundPage></GamesGroundPage>} />
                            <Route path={`/transactions`} element={<Transactions></Transactions>} />
                        </Routes >
                    </Box>
                </Paper>

            </Box>
        )
    }

}

export function parseJwt(token: string) {
    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const payload = JSON.parse(atob(base64));
        return payload;
    } catch (error) {

        console.error(error);
        window.location.href = `${process.env.REACT_APP_TAUTHWEB}/?url=${window.location.origin}`

        return null;
    }
}
import { configureStore } from '@reduxjs/toolkit'
import { authSlice } from './auth/slice'
import { ggDataSlice } from './gamesground/slice'
import { transactionsSlices } from './transactions/slice'

export const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
        ggData: ggDataSlice.reducer,
        transactions: transactionsSlices.reducer
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch